<template>
  <b-modal v-model="isModalShow" hide-footer @hide="hide" size="lg">
    <template #modal-title>
      <div class="d-md-flex justify-content-between align-items-center w-100">
        <div class="mb-2 mb-md-0">Assign To</div>
        <div v-if="isDisplayAlwayAssignAgent">
          <b-form-checkbox v-model="isAlwayAssignAgent" class="customs-checkbox"
            >Always Auto Assign to this Agent</b-form-checkbox
          >
        </div>
      </div>
    </template>
    <div>
      <InputText
        v-model="filter.search"
        placeholder="Name, Staff ID, Email, Team, Employee ID"
        type="text"
        icon="search"
        @submit="onSearchStaff"
        @onkeyup="handleKeyup"
      />
      <!-- <div class="text-center">
        <b-button variant="main-primary" @click="onSearchStaff">
          Comfirm
        </b-button>
      </div> -->
      <div class="panel-table">
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          :per-page="filter.perPage"
          :busy="isBusy"
          show-empty
          empty-text="Empty Data"
          responsive
          class="table-main"
        >
          <template v-slot:cell(ids)="data">
            <b-button
              variant="main-primary"
              :disabled="conversationInfo.lastAdminId == data.item.externalId"
              @click="onSelectStaff(data.item)"
            >
              Select
            </b-button>
          </template>
          <template v-slot:cell(id)="data">
            <p class="name">
              {{ data.item.firstname }} {{ data.item.lastname }}
              <b-badge
                v-if="data.item.isAvailable"
                pill
                variant="online"
                class="badge-status"
                >Online</b-badge
              >
              <b-badge v-else pill variant="offline" class="badge-status"
                >Offline</b-badge
              >
            </p>
            <p class="id">{{ data.item.employeeId }}</p>
            <p class="email">{{ data.item.email }}</p>
          </template>
          <template v-slot:cell(team)="data">
            <div class="team" v-if="data.item.team">
              {{ data.item.team.toString() }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-row class="no-gutters">
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-center justify-content-md-start"
          >
            <p class="my-2 my-md-0">
              Showing {{ showing === 1 ? showing : showing + 1 }} to
              {{ showingTo }} of {{ rows | numeral("0,0") }} Entries
            </p>
          </b-col>
          <b-col md class="d-md-flex text-center">
            <b-pagination
              v-model="filter.pageNo"
              :total-rows="rows"
              :per-page="filter.perPage"
              class="pagination-main mb-2 mb-md-0"
              @change="handlePagination"
              align="center"
            ></b-pagination>
            <b-form-select
              class="ml-md-3 w-auto"
              v-model="filter.perPage"
              @change="handleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";

export default {
  name: "ChatBoxModalAssignTo",
  props: {
    chatRoomId: {
      required: false,
      type: Array
    },
    conversationInfo: {
      required: false,
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    InputText
  },
  data() {
    return {
      isDisplayAlwayAssignAgent: false,
      isAlwayAssignAgent: false,
      filter: {
        search: "",
        pageNo: 1,
        perPage: 10,
        brandId: 0,
        userGUID: ""
      },
      forceRefresh: true,
      isBusy: false,
      loadFlag: false,
      isModalShow: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      items: [],
      fields: [
        {
          key: "ids",
          label: "",
          class: "col-ids"
        },
        {
          key: "id",
          label: "Name / Staff ID / Email",
          thClass: "text-center col-id",
          tdClass: "col-id"
        },
        {
          key: "team",
          label: "Team",
          class: "text-center col-team"
        }
      ],
      pageOptions: [
        { value: 10, text: `10 / Page` },
        { value: 30, text: `30 / Page` },
        { value: 50, text: `50 / Page` },
        { value: 100, text: `100 / Page` }
      ],
      timer: null
    };
  },
  computed: {
    ...mapGetters({
      brandId: "getBrand"
    })
  },
  methods: {
    hide() {
      this.isModalShow = false;
    },
    async show() {
      await this.getAssignNewChatIsAssignAgent();
      this.isModalShow = true;
      await this.clearItems();
      await this.getStaff();
      // this.onSearchStaff();
    },
    clearItems() {
      this.filter.search = "";
      this.filter.pageNo = 1;
      this.filter.perPage = 10;
      this.showing = 1;
      this.showingTo = 10;
      this.rows = 0;
      this.items = [];
    },
    onSearchStaff() {
      this.getStaff();
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    async getAssignNewChatIsAssignAgent() {
      fetch(
        `${this.$chatBaseUrl}/chatsetting/getAssignNewChatIsAssignAgent/${this.brandId}`,
        {
          method: "GET",
          headers: new Headers(this.$headersChat)
        }
      )
        .then(response => response.json())
        .then(async result => {
          if (result.result == 1) {
            this.isDisplayAlwayAssignAgent = result.detail.isAlwayAssignAgent;
          }
        })
        .catch(error => {
          console.log("error", error);
          this.isError = true;
          this.isLoading = false;
        });
    },
    getStaff: async function() {
      this.isBusy = true;

      this.filter.brandId = this.brandId;
      this.filter.userGUID = this.$store.state.chatAdminProfile.externalId;
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/user/staff/${this.brandId}`,
        this.filter
      );
      if (response.data.result) {
        this.items = response.data.detail.staffList;
        this.rows = response.data.detail.count;
      }

      this.isBusy = false;
    },
    onSelectStaff(item) {
      this.assignToStaff(item);
      // this.$emit("selectedStaff", item);
      // this.hide();
    },
    async assignToStaff(item) {
      this.$store.dispatch("setIsLoading", true);
      let body = {
        chatRoomId: this.chatRoomId,
        externalId: item.externalId,
        brandID: this.brandId,
        isAlwayAssignAgent: this.isAlwayAssignAgent
      };
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/user/staff/update`,
        body
      );
      if (response.data.result) {
        this.$store.dispatch("setDisplayCheckboxSelectUserChat", false);
        this.$store.dispatch("setIsLoading", false);
        this.hide();
      } else {
        this.$store.commit("setMessageGlobalAlertError", response.data.detail);
        this.$store.commit("setShowGlobalAlertError", true);
        this.$store.dispatch("setIsLoading", false);
      }
    },
    handlePagination(pageNo) {
      this.filter.pageNo = pageNo;
      if (pageNo === 1) {
        this.showing = this.filter.pageNo;
        this.showingTo = this.filter.perPage;
      } else {
        this.showing = pageNo * this.filter.perPage - this.filter.perPage;
        this.showingTo = this.showing + this.filter.perPage;
      }
      this.getStaff();
    },
    handleChangePerpage(perPage) {
      this.filter.pageNo = 1;
      this.filter.perPage = perPage;
      this.showing = this.filter.pageNo;
      this.showingTo = this.filter.perPage;
      this.getStaff();
    },
    handleKeyup() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onSearchStaff();
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  h5 {
    width: 100%;
    .customs-checkbox {
      font-size: 14px;
    }
  }
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
::v-deep .table-main .table td {
  vertical-align: middle;
  .team {
    overflow-wrap: break-word;
  }
}
::v-deep .col-ids {
  width: 105px !important;
  max-width: 105px !important;
  button {
    min-width: 80px;
  }
}
::v-deep .col-id {
  p {
    margin-bottom: 0;
  }
  .id {
    color: #9c9c9c;
    font-size: 12px;
  }
  .email {
    color: #9c9c9c;
    font-size: 12px;
  }
}
::v-deep .col-team {
  width: 200px !important;
  min-width: 200px !important;
}
@media (max-width: 767.98px) {
  ::v-deep .table-main {
    th,
    td {
      padding-left: 7px;
      padding-right: 7px;
    }
    .col-ids {
      button {
        min-width: unset;
        font-size: 12px;
        padding: 3px 8px;
      }
    }
    .col-id {
      min-width: 175px;
      p {
        overflow-wrap: break-word;
      }
    }
    .col-team {
      min-width: 100px !important;
      width: 100px !important;
      .team {
        width: 100px !important;
        overflow-wrap: break-word;
        font-size: 12px;
      }
    }
  }
}
</style>
